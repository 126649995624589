<template>
  <section>
    <div class="content-header">
      <h2>Privilege and oppression binaries exercise</h2>
    </div>
    <div class="content-wrapper" id="list">
      <p>Humans hold a range of identities. Some align with the dominant norms of our society and represent a “majority identity”. Others don’t align with the dominant norms of our society and represent a “minority identity”.</p>
      <p>Where we hold minority identities, we have the potential to experience systemic and overt oppression. Why? Because many of these minority identities are maligned or perceived as and valued less within our society.</p>
      <p>This Privilege & Oppression Binaries Exercise explores the range of human identities that may lead us to experiencing privilege and oppression.</p>
      <h3>How to complete the activity</h3>
      <p>On the left are identities aligned with “dominant norms”, which might confer privilege. On the right are identities misaligned with the “dominant norms” of our society, which might lead to experiences of oppression. Reflect on the identities on the left and look for what could be the counterpart on the right.</p>
      <h3>While you’re completing the activity</h3>
      <p>Reflect on the ways our identities might intersect to influence our own experience and that of others. Take note of any new terms or identities that surprised you.</p>
      <div class="row">
        <div class="col-6">
          <ul class="panel-list panel-list--orange">
            <li>Middle-High Income (Childhood)</li>
            <li>Born in Canada</li>
            <li>Urban</li>
            <li>Age of social value</li>
            <li>Middle-high income (Adult / Current)</li>
            <li>No Mental Illness / Psychiatric Diagnosis</li>
            <li>Postsecondary Credential</li>
            <li>Neurotypical</li>
            <li>English is First Language</li>
            <li>Heterosexual</li>
            <li>Settler</li>
            <li>Middle-Upper 'Class' (Professional, Independently Wealthy)</li>
            <li>Literate</li>
            <li>White</li>
            <li>Canadian Citizen</li>
            <li>No Trauma / Violence History</li>
            <li>Cisgender</li>
            <li>Temporarily Abled</li>
            <li>Married</li>
            <li>Well Employed</li>
            <li>Appearance of Social Value ('Attractive')</li>
            <li>Christian</li>
          </ul>
        </div>
        <div class="col-6">
          <transition-group name="flip-list" tag="ul" class="panel-list">
            <li v-for="item in shuffledList" v-bind:key="item">{{item}}</li>
          </transition-group>
        </div>
      </div>
      <button class="btn-nav" @click="sortList" v-scroll-to="'#list'">Reveal counterparts </button>
      <p><em>Note that the list in the activity is far from exhaustive, and there are many ways we might experience privilege or disadvantage/oppression based on identities across multiple levels and systems, within community contexts, within family contexts, developmentally and individually.</em></p>

      <h3>When you’re done…</h3>
      <p>Remember that we all hold aspects of identity or experience that do not align with dominant social norms. </p>
      <p>And while we may not share identities with students we serve, we can leverage our own experiences with suffering or disadvantage to empathize with individuals who might experience challenges we do not. </p>
      <p>Reflecting on this exercise and your own positionality: What binaries might intersect, leading to more complex challenges with oppression or disadvantage?</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

import _ from 'lodash'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,

      // Randomized data
      orderedList:
        [
          'Low Income (Childhood)',
          'Immigrant, Refugee',
          'Rural',
          'Very young / elderly',
          'Low income (Adult / Current)',
          'Presence of Mental Health Condition / Psychiatric Diagnosis',
          'No High School Diploma, no PSE Credential',
          'Presence of neurodevelopmental condition (e.g. learning disability, ADHD, autism spectrum disorder)',
          'English is Acquired Language',
          'Lesbian, Gay, Bisexual, A-sexual',
          'Indigenous',
          'Working / Lower Class',
          'Illiterate',
          'Racialized',
          'Permanent Resident, Travel Visa (work, school, travel), undocumented immigrant',
          'Survivor of Trauma / Violence',
          'Transgender/2-Spirit',
          'Presence of Disability',
          'Single, non-monogamous/polyamorous, partnered',
          'Unemployed, Precariously Employed, Underemployed, On Leave',
          'Appearance does not align with social values of attractiveness',
          'Non-Christian Spiritual Identity (e.g. Muslim, atheist, Jewish, Buddhist, denominational / spiritual, Indigenous belief system)'
        ],

      shuffledList: []
    }
  },

  methods: {
    sortList () {
      this.shuffledList = this.orderedList
    }
  },

  mounted () {
    this.shuffledList = _.shuffle(this.orderedList)
  }
}
</script>

<style>
.flip-list-move {
  transition: transform 1s;
}
</style>
